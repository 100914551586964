@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../node_modules/ol/ol.css";

body {
	margin: 0;
	font-family: "Montserrat", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media (min-width: 640px) {
	.ol-zoom {
		top: 1em !important;
		right: 0.5em !important;
		left: initial !important;
	}
}

.ol-rotate {
	top: 9em;
}

.ol-zoom-sm {
	top: 6em;
	right: 0.5em;
	left: initial;
}

.ol-control button {
	background-color: #000000cc;
	color: lightgray;
	width: 2em;
	height: 2em;
}

.ol-control button:hover {
	background-color: rgba(229 231 235);
	color: black;
}

.ol-control button:focus {
	background-color: rgba(229 231 235);
	color: black;
}

.scroll-bar::-webkit-scrollbar {
	width: 20px; /* width of the entire scrollbar */
}

.scroll-bar::-webkit-scrollbar-track {
	background: #f4f4f6;
}

.scroll-bar::-webkit-scrollbar-thumb {
	background-color: #56595a; /* color of the scroll thumb */
}

.scroll-bar::-webkit-scrollbar-button:single-button {
	background-color: #56595a;
	display: block;
	border-style: solid;
	height: 13px;
	width: 16px;
}

.scroll-bar::-webkit-scrollbar-button:single-button:vertical:decrement {
	border-radius: 10px 10px 0px 0px;
	height: 20px;
	background-image: url("./scrollup.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 8px 5px;
	border-color: #56595a;
}

.scroll-bar::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
	border-color: transparent transparent #bbbbbb transparent;
}

.scroll-bar::-webkit-scrollbar-button:single-button:vertical:increment {
	border-radius: 0px 0px 10px 10px;
	height: 20px;
	background-image: url("./scrolldown.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 8px 5px;
	border-color: #56595a;
}

.scroll-bar::-webkit-scrollbar-button:single-button:vertical:increment:hover {
	border-color: #777777 transparent transparent transparent;
}

/* Left (Horizontal Decrement) */
.scroll-bar::-webkit-scrollbar-button:single-button:horizontal:decrement {
	border-radius: 10px 0px 0px 10px;
	width: 20px;
	background-image: url("./scrollleft.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 5px 8px;
	border-color: #56595a;
}

.scroll-bar::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
	border-color: transparent #bbbbbb transparent transparent;
}

/* Right (Horizontal Increment) */
.scroll-bar::-webkit-scrollbar-button:single-button:horizontal:increment {
	border-radius: 0px 10px 10px 0px;
	width: 20px;
	background-image: url("./scrollright.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 5px 8px;
	border-color: #56595a;
}

.scroll-bar::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
	border-color: transparent transparent transparent #777777;
}

.ol-scale-line {
	background: white !important;
}

input[type="search"]::-webkit-search-cancel-button {
	background: red;
}

.ant-popover-title {
	margin-bottom: 0px !important;
}

.maplibregl-popup-tip {
	display: none !important;
}

.maplibregl-popup-content {
	background: none !important;
	border-radius: 0 !important;
	box-shadow: none !important;
	padding: 0 !important;
}
